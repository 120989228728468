* {
  transition: all linear 0.2s;
}
.logo {
  /* max-width: 300px; */
  max-height: 180px;
  padding-top: 20px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* height: 6000px; */
  /* background-color: #353434; */
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}
*::selection {
  background-color: #008080; /* Set your desired background color */
  color: rgb(255, 255, 255); /* Set your desired text color */
  border-radius: 89px !important; /* Set the desired border radius value */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide the scrollbar by default */
::-webkit-scrollbar {
  width: 0.5em;
  position: absolute;
  opacity: 0;
  z-index: 1;
  background: rgba(222, 222, 222, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.5s 0.5s ease-out;
  transition: opacity 0.5s 0.5s ease-out;
}

/* Show the scrollbar when scrolling */
::-webkit-scrollbar-thumb {
  position: absolute;
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  background: rgb(70, 70, 70);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.5s 0.5s ease-out;
  transition: opacity 0.5s 0.5s ease-out;
}

/* Customize the scrollbar track appearance */
::-webkit-scrollbar-track {
  position: absolute;
  opacity: 0;
  z-index: 1;
  background: rgba(222, 222, 222, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.5s 0.5s ease-out;
  transition: opacity 0.5s 0.5s ease-out;
}
.scrollbar-track {
  position: absolute;
  opacity: 0;
  z-index: 1;
  background: rgba(222, 222, 222, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.5s 0.5s ease-out;
  transition: opacity 0.5s 0.5s ease-out;
}

/* Customize the styles of navigation buttons */
/* Use !important to ensure the style takes precedence */
.swiper-button-prev,
.swiper-button-next {
  /* Center vertically */
  color: black !important;
  /* background-color: #000; */
}

/* Customize the styles of pagination */

.swiper {
  padding-bottom: 30px !important;
}
.swiper-pagination {
  /* background-color: #333; */
}

.swiper-pagination-bullet {
  background-color: #000000 !important; /* Change bullet color */
}

.swiper-pagination-bullet-active {
  background-color: #000000 !important; /* Change active bullet color */
}
